//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-300:_5896,_6248,_6268,_2752,_4108,_2236,_1300,_7660;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-300')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-300', "_5896,_6248,_6268,_2752,_4108,_2236,_1300,_7660");
        }
      }catch (ex) {
        console.error(ex);
      }